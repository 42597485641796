
















































import { Component, Vue, Prop, Watch, Provide } from 'vue-property-decorator'
import { User, ViewItem, RuntimeVueBlock } from '@/models'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import gql from 'graphql-tag'
import Loading from './Loading.vue'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'
import { ApolloError } from 'apollo-client'
import { VueBlockCreateFragment } from './fragments'
import { alert, confirm, confirmDelete, prompt } from '@/components/dialogs'
// Custom Components
import CodelessComponent from './components/CodelessComponent.vue'
import Fields from '@/components/form/Fields.vue'
import Field from '@/components/fields/Field.vue'
import FileSelect from '@/components/fields/file/UploadModal.vue'
import draggable from 'vuedraggable'
import _ from 'lodash'
import moment from '@/plugins/moment'
// External libraries
// import XLSX from 'xlsx'
import {
  VueApexCharts as ApexChart,
  ApexCharts,
  es
} from '@/plugins/apexcharts'

interface FilterValue {
  filterId?: string
  filterOptions: Record<string, any>
}

@Component({
  name: 'BlockView',
  components: {
    Loading,
    ComponentHeader,
    CollectionWatcher
  },
  apollo: {
    block: {
      query: gql`
        query getVueBlock($vueBlockId: ID) {
          block: vueBlock(vueBlockId: $vueBlockId) {
            ...VueBlockCreate
            name
            title
            roles
            script
            renderFn
            compiledStyle
            apiCalls {
              name
            }
          }
        }
        ${VueBlockCreateFragment}
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          vueBlockId: this.componentId
        }
      },
      error(e: ApolloError) {
        this.error = e
      }
    },
    blockWithData: {
      query: gql`
        query getVueBlockWithData($vueBlockId: ID, $params: JSON) {
          blockWithData: vueBlock(vueBlockId: $vueBlockId) {
            ...VueBlockCreate
            autoUpdateData
            injectNewData
            data(params: $params)
          }
        }
        ${VueBlockCreateFragment}
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          vueBlockId: this.componentId,
          params: this.viewParams
        }
      },
      error(e: ApolloError) {
        this.error = e
      }
    }
  }
})
export default class VueBlockView extends Vue {
  @Prop({ type: String }) environmentId!: string
  @Prop({ type: String }) componentId!: string
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Boolean, default: false }) editing!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) itemDefinition!: ViewItem
  @Prop({ type: Boolean, default: false }) showCloseButton!: boolean

  block: RuntimeVueBlock | null = null
  blockWithData: RuntimeVueBlock | null = null
  staticData: Record<string, any> | null = null
  error: any = null
  contentComponent = {
    name: 'VueBlockLoading',
    render(h: any) {
      return h(Loading, '')
    }
  }

  @Provide()
  codelessRuntimeVars() {
    return {
      environmentId: this.environmentId,
      viewParams: this.viewParams,
      preview: this.preview,
      editing: this.editing,
      setParams: (params: Record<string, any>) =>
        this.$emit('setParams', params)
    }
  }

  get dependencies() {
    if (
      !this.blockWithData ||
      !this.blockWithData.data ||
      !this.blockWithData.autoUpdateData
    ) {
      return { collections: {} }
    }
    return this.blockWithData.data.__dependencies
  }

  get styleTag() {
    if (!this.block || !this.block.compiledStyle) {
      return ''
    }
    return `<style>${this.block.compiledStyle}</style>`
  }

  refetchData() {
    return this.$apollo.queries.blockWithData.refetch()
  }

  @Watch('blockWithData')
  onData(blockWithData: RuntimeVueBlock | null) {
    if (!blockWithData || !blockWithData.data) return
    const content = this.$refs.content as any
    if (!this.staticData || blockWithData.injectNewData) {
      this.staticData = blockWithData.data
    } else if (this.$refs.content && blockWithData.autoUpdateData) {
      if (content.onData) content.onData(blockWithData.data)
    }
    if (content && blockWithData.injectNewData) {
      // this.updateContentComponent()
      const content = this.$refs.content as any
      Object.assign(content, blockWithData.data)
      if (content.onData) content.onData(blockWithData.data)
    }
  }

  @Watch('block')
  async updateContentComponent() {
    try {
      const block = this.block!
      if (!block || !this.staticData)
        this.contentComponent = {
          name: 'VueBlockError',
          render(h: any) {
            return h(Loading, '')
          }
        }
      const AsyncFunction = Object.getPrototypeOf(
        async function () {}
      ).constructor
      const properties = await new AsyncFunction(
        '_',
        'moment',
        block.script || 'return {}'
      )(_, moment)
      const invokeApiCall = this.invokeApiCall.bind(this)
      const parent = this
      const codelessMixin = <any>{
        computed: {
          $dialogs: () => ({
            alert,
            confirm,
            confirmDelete,
            prompt
          }),
          $api: () => {
            const apiCalls: Record<string, any> = {}
            for (const apiCall of block.apiCalls) {
              apiCalls[apiCall.name] = (params: Record<string, any>) =>
                invokeApiCall(apiCall.name, params)
            }
            return apiCalls
          },
          $params: () => parent.viewParams
        },
        methods: <any>{
          $setParams(newParams: Record<string, any>) {
            parent.$emit('setParams', newParams)
          }
        }
      }

      this.contentComponent = {
        ...properties,
        components: {
          ...(properties.components || {}),
          CodelessComponent,
          Fields,
          Field,
          FileSelect,
          draggable,
          apexchart: import('@/plugins/apexcharts').then(v => v.VueApexCharts)
        },
        name: 'RenderedVueBlock',
        props: ['viewParams'],
        mixins: [codelessMixin],
        data: () => parent.staticData,
        render: new Function(
          block.renderFn!.render || 'with(this){return _c("div", "") }'
        ),
        staticRenderFns: (block.renderFn!.staticRenderFns || []).map(
          (fn: any) => new Function(fn)
        )
      }
    } catch (e) {
      console.error(e)
      const isAdmin = this.isAdmin
      this.contentComponent = {
        name: 'VueBlockRenderError',
        render(h: any) {
          return h(
            'div',
            {
              class: 'text-center'
            },
            [
              h('v-icon', { props: { size: 96 } }, 'broken_image'),
              isAdmin
                ? h('pre', { class: 'error--text error-display' }, e.message)
                : null
            ]
          )
        }
      }
    }
  }

  async invokeApiCall(name: string, params: Record<string, any>) {
    const result = await this.$apollo.mutate({
      mutation: gql`
        mutation ($vueBlockId: ID, $apiCallName: String, $params: JSON) {
          result: invokeVueBlockApiCall(
            vueBlockId: $vueBlockId
            apiCallName: $apiCallName
            params: $params
          )
        }
      `,
      // Parameters
      variables: {
        vueBlockId: this.componentId,
        apiCallName: name,
        params: { ...this.viewParams, ...params }
      }
    })
    const res = result.data.result
    if (res.success) {
      return res.result
    } else {
      throw new Error(res.error)
    }
  }

  get user(): User {
    return this.$store.state.auth.user
  }

  get isAdmin() {
    return (
      this.user.roles.indexOf('admin') >= 0 ||
      this.user.roles.indexOf('superAdmin') >= 0
    )
  }

  get errorMessage() {
    if (!this.error) return ''
    return this.error.message
  }
}
